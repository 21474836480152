<template>
<div>
    <b-jumbotron class="bam" text-variant="white" border-variant="light">

        <template #header>Build. Play. Learn.</template>

        <template #lead>
            Learn about AI by creating your own.
        </template>

        <b-container fluid>
            <b-row>
                <b-col>
                    <p>
                        An <b>open source</b> platform focused on making AI experimentation easy and fun.
                    </p>
                    <ul>
                        <li>
                            Intuitive UI for creating and training agents.
                        </li>
                        <li>
                            Growing selection of agent algorithms and environments.
                        </li>
                        <li>
                            Extension system for adding new algorithms and environments.
                        </li>
                        <li>
                            Python API for complicated tasks.
                        </li>
                        <li>
                            Single and Multi Agents Environment Competitions (Coming soon).
                        </li>
                        <li>
                            Educational resources (Coming soon).
                        </li>
                    </ul>
                </b-col>
                <b-col class="text-center">
                    <img height="300px" src="@/assets/ai-play_8.png" />
                </b-col>
            </b-row>
        </b-container>

    </b-jumbotron>
    <div class="mt-5 mb-5">
    </div>
    <b-container>
        <b-row>
            <b-col class="my-auto text-center">
                <b-button variant="primary" href="https://pistarlab.readthedocs.io/en/latest/installation.html" size="lg"> Install </b-button>
            </b-col>

            <!-- <b-col class="my-auto text-center">
                <b-button variant="info" href="http://3.143.43.5:7777/#/" size="lg"> Demo Instance</b-button>
            </b-col> -->

        </b-row>
        <div class="mt-5 mb-5">

        </div>
        <b-row>
            <b-col class="text-center my-auto">
                WARNING: In early development and not ready for general use.
            </b-col>

        </b-row>
        <hr />
        <div class="mt-5 mb-5">

        </div>
        <b-row>
            <b-col class="my-auto text-center ">
                <h4>Train agents on a growing selection environments.</h4>

            </b-col>
            <b-col class="my-auto text-center">
                <img class="shadowbox" style="width:500px" src="@/assets/envs.png" />

            </b-col>
        </b-row>
        <div class="mt-5 mb-5">

        </div>

        <b-row>

            <b-col class="my-auto text-center">

                <img class="shadowbox" style="width:500px" src="@/assets/recent_agents.png" />
            </b-col>
            <b-col class="my-auto text-center">
                <h4>Use an existing algorithm or bring your own.</h4>
            </b-col>
        </b-row>
        <div class="mt-5 mb-5">

        </div>

        <b-row>
            <b-col class="my-auto text-center">
                <h4>Watch agents learn in real-time.</h4>
            </b-col>

            <b-col class="my-auto text-center">

                <img class="shadowbox" style="width:500px" src="@/assets/multi_agent_viewer.png" />
            </b-col>
        </b-row>
        <div class="mt-5 mb-5">

        </div>
        <hr />
        <div class="mt-5 mb-5">

        </div>
    </b-container>
    <b-container>
        <row>
            <b-col>
                <div class="mt-5 mb-5 text-center">
                    <h2>Something for everyone...</h2>

                </div>
                <div>
                    <ul class="ml-5 biglist">
                        <li class="head">
                            <h4> AI Enthusiasts and Students</h4>
                            <ul>
                                <li class="bul">
                                    Have fun experimenting with state-of-the-art AI Agents. No coding required.

                                </li>
                                <li class="bul">
                                    Gain a better intuition about reinforcement learning by creating your own agents.
                                </li>
                            </ul>
                        </li>
                        <li class="head">
                            <h4> Reinforcement Learning Researchers and Engineers</h4>
                            <ul>
                                <li class="bul">
                                    Showcase your algorithms by creating extensions for other users to enjoy. No restrictive framework/library requirements.
                                </li>
                                <li class="bul">

                                    Import your externally trained agents.
                                </li>
                                <li class="bul">
                                    Use piSTAR Lab in your development workflow.
                                </li>
                                <li class="bul">
                                    Try your algorithm on newly available environments with just a few clicks
                                </li>

                            </ul>
                        <li class="head">
                            <h4>
                                Game and Environment Developers
                            </h4>
                            <ul>
                                <li class="bul">
                                    Create Extensions which make your game playable by piSTAR Agents.
                                </li>
                                <li class="bul">
                                    Train your own Agents for your game’s AI
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </b-col>
        </row>
    </b-container>
</div>
</template>

<script>
export default {
    name: 'Home',
    components: {}
}
</script>
